import React from 'react';
import { Layout, Typography, Row, Col, Card, Menu, Dropdown,Grid } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import heroImage from './/assets/NothingRest1.png';
import designConceptsImage from './/assets/teaserTeaser2.png';
import modelsImage from './/assets/model.png';
import renderingsImage from './/assets/teaserTeaser4.png';
import SourcesSection from './SourceSection';
const { useBreakpoint } = Grid;
const { Header, Content, Footer } = Layout;
const { Title } = Typography;
const cardStyle = {
  margin: '16px',
  marginLeft: '0px',
  borderRadius: '8px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  width: '100%',
};
const menuItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
  },
  {
    title: 'Gallery',
    path: '/gallery',
  },
];


const HomePage = () => {
    const screens = useBreakpoint();
  return (
    <Layout>
      <Header style={{ backgroundColor: '#1abc9c', position:'sticky', top:0, zIndex:5}}>
        <Row justify="left" align="middle" style={{top:-30, position:"relative"}}>
          <Col>
            <Title style={{ color: 'white', fontSize: screens.sm  ? "2.7em" : "2em" }}>3DPrintedCat Studios</Title>
          </Col>
          
        </Row>
      </Header>
      <Content style={{ padding: "16px"}}>
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <img src={heroImage} alt="Hero" style={{ maxWidth: '100%', borderRadius:"8px" }} />
          </Col>
          <Col xs={24} md={12}>
             
            <Title level={2} style={{fontSize:"3em"}}>Welcome to 3DPrintedCat Studios</Title>
            <p style={{color:"white", fontSize: screens.sm ? "2em" :"1.3em"}}>
              Skilled designer and tech enthusiast who
              creates innovative concepts, designs, and renders using the latest technologies.
            </p>
          </Col>
        </Row>
        <Title level={3}>Services</Title>
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Card
              title="Design Concepts"
              style={cardStyle}
              cover={<img src={designConceptsImage} alt="Design Concepts" />}
            >
              <p>
                Can create unique design concepts based on your
                requirements and specifications, using the latest software tools
                and techniques.
              </p>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card
              title="3D Models"
              style={cardStyle}
              cover={<img src={modelsImage} alt="3D Models" />}
            >
              <p>
                Can create 3D models of your products, prototypes,
                or ideas, allowing you to visualize and test them before
                production.
              </p>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card
              title="Renderings"
              style={cardStyle}
              cover={<img src={renderingsImage} alt="Renderings" />}
            >
              <p>
                Can create photorealistic renderings of your
                products or concepts, allowing you to showcase them to potential
                customers or investors.
              </p>
            </Card>
          </Col>
        </Row>
        <SourcesSection /> 
      </Content>
      <Footer style={{ backgroundColor: '#1abc9c', textAlign: 'center' }}>
        <p style={{ color: 'white' }}>
          © 2023 3DPrintedCat Studios. All rights reserved.
        </p>
      </Footer>
    </Layout>
  );
};

export default HomePage;