import React from 'react';
import { ConfigProvider, Layout, Typography} from 'antd';
import './App.css';
import HomePage from './components/homepage';

const { Header, Content } = Layout;
function App() {
  return (
    <ConfigProvider
        theme={{
          token: {
            // Set the theme colors using state
            colorPrimary: '#7FB069',
            colorBgBase: '#282c34',
            borderRadius:"8px",
            colorTextBase: '#cccccc',
            colorInfo:"#6CA6C1",
            colorError:"#EF767A",
            colorSuccess:'#7FB069',
            colorWarning:'#EDAE49'
          },
        }}
      >
    <Layout className="layout">
      <Content >
       <HomePage/>
      </Content>
    </Layout>
    </ConfigProvider>
  );
}

export default App;
