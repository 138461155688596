import React from 'react';
import { Typography, Row, Col, Avatar } from 'antd';

const { Title } = Typography;

const sources = [
    {
      name: 'Nothing',
      logo: 'https://pbs.twimg.com/profile_images/1510020431696310279/fhgfNZ44_400x400.jpg',
      link: 'https://twitter.com/nothing/status/1653003200675434497?s=20'
    },
  {
    name: 'Dryo Tech',
    logo: 'https://secure.gravatar.com/avatar/bac9a9e2af48a5c671dc24296d03b1d6',
    link: 'https://dryotech.com/nothing-fold-1-news/',
  },
  {
    name: 'DNA India',
    logo: 'https://english.cdn.zeenews.com/static/apprun/dna/icons/logodna.png',
    link: 'https://www.dnaindia.com/web-stories/technology/mobile/nothing-phone-2-launch-confirmed-image-design-nothing-phone-1-india-1683134642643',
  },
  {
    name: 'Arabhardware',
    logo: 'https://arabhardware.net/theme-assets/images/logo.svg',
    link: 'https://arabhardware.net/news/nothing-phone-2-tease'
  },
  {
    name: 'Gizchina',
    logo: 'https://www.gizchina.com/wp-content/uploads/images/2021/03/gizchina-logo-google.png',
    link: 'https://www.gizchina.com/2023/05/05/nothing-fold-1-could-be-the-new-king-of-foldable-phones/',
  },
  {
    name: 'Girafa',
    logo: 'https://g-rafa.co.il/wp-content/uploads/2017/12/Girafa-Logo.png',
    link: 'https://g-rafa.co.il/nothing-introducing-fold-1',
  },
];

const SourcesSection = () => {
  return (
    <div style={{ marginTop: '32px' }}>
      <Title level={3}>Work Featured On</Title>
      <Row justify="space-around" align="middle">
        {sources.map((source) => (
          <Col key={source.name} xs={12} md={6} style={{ textAlign: 'center' }}>
            <a href={source.link} target="_blank">
              <Avatar size={64} src={source.logo} />
              <div style={{ marginTop: '8px' }}>{source.name}</div>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default SourcesSection;
